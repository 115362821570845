import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AppContext } from '../../../context';
import { ClientAmountPerPointsProps } from '../../types';
import { getAmountPerPoints, getClientCurrency, getPointsPerAmount } from '../../../services/client';
import { cliente } from '../../constants';
import { ID_TYPE_OF_TRANSACTION } from '../../constants/client';

interface Client {
  pointsPerAmount?: number;
  clientCurrency?: boolean;
  amountPerPoints?: ClientAmountPerPointsProps;
}

export const useClient = ({ pointsPerAmount, clientCurrency, amountPerPoints }: Client) => {
  const appContext = useContext(AppContext);
  const idUsuario: number = appContext?.user?.idUsuario;

  const pointsPerAmountQuery = useQuery({
    queryKey: ['points-per-amount', { idUsuario, pointsPerAmount }],
    queryFn: async () =>
      await getPointsPerAmount({
        idUsuario,
        idCliente: cliente,
        idTipoMovimiento: ID_TYPE_OF_TRANSACTION.redeem,
        importe: pointsPerAmount,
      }),
    enabled: Boolean(idUsuario && pointsPerAmount > 0),
  });

  const clientCurrencyQuery = useQuery({
    queryKey: ['client-currency', { idUsuario }],
    queryFn: async () => await getClientCurrency(cliente),
    enabled: Boolean(cliente && clientCurrency),
  });

  const amountPerPointsQuery = useQuery({
    queryKey: ['amount-per-points', { idUsuario, points: amountPerPoints?.puntos }],
    queryFn: async () => await getAmountPerPoints({ idUsuario, ...amountPerPoints }),
    enabled: Boolean(idUsuario && amountPerPoints?.puntos > 0),
  });

  return { idUsuario, pointsPerAmountQuery, clientCurrencyQuery, amountPerPointsQuery };
};
