import { useState } from 'react';
import { ViewTable } from '../../components/ViewTable/ViewTable';
import { useMyTransactions } from '../../hooks/transactions/useMyTransactions';

export const MyTransactions = () => {
  const {
    myTransactionsColumns,
    myTransactionsRows,
    isLoading,
    dictionary,
    page,
    sort,
    totalPages,
    changeSort,
    setPage,
    setSearch,
    handleChangePage,
    typesOfMovements,
    setSelectedType,
    selectedType,
  } = useMyTransactions();

  return (
    <ViewTable
      title={dictionary.title}
      searchBarPlaceHolder={dictionary.form.search.placeHolder}
      isLoading={isLoading}
      rows={myTransactionsRows}
      page={page}
      totalPages={totalPages}
      noResults={dictionary.table.noResults}
      columns={myTransactionsColumns}
      sort={sort}
      showSearchBar={false}
      setPage={setPage}
      changeSort={changeSort}
      setSearch={setSearch}
      handleChangePage={handleChangePage}
      isMyMovements={true}
      typesOfMovements={typesOfMovements}
      selectedType={selectedType}
      setSelectedType={setSelectedType}
    />
  );
};
