import { useEffect } from 'react';
import { cliente } from '../../common/constants';
import { OrderOperation } from '../../common/types';
import { useClient, usePerson } from '../../common/hooks';
import { ID_TYPE_OF_TRANSACTION } from '../../common/constants/client';
import { calculatePointsPerCrypto } from '../../utils';
import { useCryptoPointsExchanger } from '.';

export const usePurchaseCrypto = () => {
  const {
    dictionary,
    currentCurrency,
    isExchangeDisabled,
    order,
    isRedeeming,
    textFields,
    exchangeRates,
    setTextFields,
    setIsRedeeming,
    handleCurrencyOnSelect,
    handlePointsOnChange,
    handleSubmit,
  } = useCryptoPointsExchanger();

  const {
    clientPointsQuery: { data: userPoints },
  } = usePerson({ isFetchingUserPoints: true });

  const {
    amountPerPointsQuery: { data: amountPerPoints },
  } = useClient({
    amountPerPoints: {
      idCliente: cliente,
      idTipoMovimiento: ID_TYPE_OF_TRANSACTION.exchangeCrypto,
      puntos: Number(textFields.points),
    },
    clientCurrency: true,
  });

  const handleBuyCrypto = async (): Promise<void> => {
    const submitOrder: void = await handleSubmit(OrderOperation.Buy, amountPerPoints);

    return submitOrder;
  };

  useEffect(() => {
    setTextFields((prevTextFields) => ({
      ...prevTextFields,
      crypto: calculatePointsPerCrypto(amountPerPoints, exchangeRates?.quotes, currentCurrency) || 0,
    }));
  }, [amountPerPoints, exchangeRates?.quotes, currentCurrency, setTextFields]);

  return {
    currentCurrency,
    textFields,
    userPoints,
    order,
    dictionary,
    exchangeRates,
    isRedeeming,
    isExchangeDisabled,
    setTextFields,
    setIsRedeeming,
    handleCurrencyOnSelect,
    handlePointsOnChange,
    handleBuyCrypto,
  };
};
