import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import ShortLine from '../ShortLine/ShortLine';
import CustomSearch from '../CustomSearch/CustomSearch';
import CustomTable from '../CustomTable/CustomTable';
import { ITable } from '../../common/types';
import { RUTAS } from '../../common/constants/rutas';

export const ViewTable = (table: ITable) => {
  const {
    title,
    searchBarPlaceHolder,
    isLoading,
    rows,
    page,
    totalPages,
    noResults,
    columns,
    sort,
    actions,
    showSearchBar,
    details,
    changeSort,
    setSearch,
    setPage,
    handleChangePage,
    typesOfMovements,
    selectedType,
    setSelectedType,
  } = table;
  const location = useLocation();
  const isMisMovimientos = location.pathname === RUTAS.misMovimientos;

  return (
    <Box sx={{ padding: '30px 20px' }}>
      <Typography variant="h4" color="primary">
        {title}
        <ShortLine position="left" />
      </Typography>

      <Box padding={'0 0 20px 0'}>{details && details}</Box>

      <Box border={2} borderColor={'primary.main'} borderRadius={3} padding={'30px'} width={'100%'}>
        {isMisMovimientos && typesOfMovements.length > 0 && (
          <FormControl fullWidth margin="normal">
            <InputLabel>Tipo de Movimiento</InputLabel>
            <Select
              value={selectedType || ''}
              label="Tipo de Movimiento"
              onChange={(e) => {
                const selectedType = e.target.value;
                setSelectedType(selectedType);
              }}
            >
              {typesOfMovements.map((typeMovement, index) => {
                return (
                  <MenuItem key={index} value={typeMovement.id}>
                    {typeMovement.descripcion}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
        {showSearchBar && <CustomSearch placeHolder={searchBarPlaceHolder} setSearch={setSearch} setPage={setPage} />}

        <CustomTable
          columns={columns}
          data={rows}
          isLoading={isLoading}
          page={page}
          totalPages={totalPages}
          sort={sort}
          noResults={noResults}
          handleChangePage={handleChangePage}
          changeSort={changeSort}
        />

        {actions && actions}
      </Box>
    </Box>
  );
};
