import { patchConfirmOrder } from '.';
import { API_LIRIUM_ORDER_URL, cliente, headerOptions } from '../../common/constants';
import { ID_TYPE_OF_TRANSACTION } from '../../common/constants/client';
import {
  ConfirmOrderProps,
  ExternalAPIConfirmOrderProps,
  ExternalAPIConfirmOrderResponse,
  NewTransactionResponse,
  OrderOperation,
  PostTransaction,
} from '../../common/types';
import { fetchContent } from '../../utils';
import { handleServiceError } from '../GiftCardService';
import { postTransaction } from '../transactions';

export const postConfirmOrder = async (props: ConfirmOrderProps): Promise<void> => {
  const { idPersona, currency, amount, ordenId, customerId, points, operation, quantity } = props;

  const URL: string = API_LIRIUM_ORDER_URL.confirmOrder
    .replace('{customerId}', customerId)
    .replace('{ordenId}', ordenId);

  const orderConfirmation: ExternalAPIConfirmOrderProps = {
    customer: {
      currency,
      amount,
    },
  };

  try {
    const externalAPIResponse: ExternalAPIConfirmOrderResponse = await fetchContent(URL, {
      method: 'POST',
      headers: headerOptions,
      body: JSON.stringify(orderConfirmation),
    });

    const movimientoDetalles = [];
    const typeOfOperation = operation === OrderOperation.Buy ? 'compraron' : 'vendieron';

    movimientoDetalles.push({
      ...(operation === OrderOperation.Buy ? { puntos: points } : { importe: points }),
      cantidad: 1,
    });

    const transaction: PostTransaction = {
      fecha: new Date(Date.now()).toISOString(),
      idCliente: cliente,
      idPersona,
      idTipoMovimiento: ID_TYPE_OF_TRANSACTION.exchangeCrypto,
      idEstado: null,
      comentario: `Se ${typeOfOperation} ${quantity} ${currency}`,
      movimientoDetalles,
    };

    const newTransaction: NewTransactionResponse = await postTransaction(transaction);

    const newOrder: void = await patchConfirmOrder({
      idOrder: ordenId,
      state: externalAPIResponse?.state,
      idMovimiento: newTransaction?.id,
    });

    return newOrder;
  } catch (error: any) {
    console.error('[postLiriumOrder]: ' + error);
    throw handleServiceError(error);
  }
};
