import { useCallback, useContext } from 'react';
import { AppContext } from '../../context';
import { cliente } from '../constants';

export const useAppContext = () => {
  const appContext = useContext(AppContext);

  // const handleLogout = (): void => appContext?.cerrarSession();
  const handleLogout = useCallback(() => {
    appContext?.cerrarSession();
  }, [appContext]);

  return {
    user: appContext?.user,
    userName: appContext?.user?.nombre,
    lastName: appContext?.user?.apellido,
    email: appContext?.user?.email,
    idUsuario: appContext?.user?.idUsuario,
    idPersona: appContext?.user?.idPersona,
    idCliente: cliente,
    permissions: appContext?.permisos,
    handleLogout,
  };
};
