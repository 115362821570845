import React from 'react';
import {
  Typography,
  Button,
  Box,
  Drawer,
  Badge,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemAvatar,
  Avatar,
  Divider,
} from '@mui/material';
import {
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
  ShoppingCartOutlined as ShoppingCartOutlinedIcon,
} from '@mui/icons-material';
import { carritoItemsInterface } from '../../common/interfaces/CarritoInterface';
import { formatNumber } from '../../utils/functions';
import useCarrito from '../../common/hooks/useCarrito';
import { SHOPPING_CART_STYLES } from './styles';

export const ShoppingCart = () => {
  const { dictionary, open, carrito, amountOfItemsAdded, setOpen, handleRemove, navigate, toggleDrawer } = useCarrito();

  return (
    <>
      <Badge badgeContent={amountOfItemsAdded} color="secondary">
        <Box onClick={() => setOpen(!open)} sx={SHOPPING_CART_STYLES.cartIconContainerHover()}>
          <ShoppingCartOutlinedIcon sx={SHOPPING_CART_STYLES.cartIcon} />
        </Box>
      </Badge>
      <Drawer
        open={open}
        onClose={toggleDrawer(true)}
        anchor="right"
        PaperProps={{ sx: SHOPPING_CART_STYLES.isScrollBarVisible(carrito?.length) }}
        sx={SHOPPING_CART_STYLES.drawer}
      >
        <Box
          width={{ xs: '100vw', sm: 370, md: 370 }}
          sx={SHOPPING_CART_STYLES.dropDownContainer}
          role="presentation"
          onKeyDown={toggleDrawer(false)}
        >
          <Box sx={SHOPPING_CART_STYLES.headerContainer}>
            <Box sx={SHOPPING_CART_STYLES.titleContainer}>
              <ShoppingCartOutlinedIcon onClick={() => setOpen(!open)} color="primary" />
              <Typography variant="h6" color="primary">
                {dictionary?.titulo}
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="btnSecundary"
              onClick={() => {
                navigate('/carrito');
                setOpen(false);
              }}
            >
              {dictionary?.irAlCarrito}
            </Button>
          </Box>

          <List>
            {carrito?.map((el: carritoItemsInterface, index: number) => (
              <React.Fragment key={el?.idProducto}>
                <Divider variant="middle" component="li" />
                <ListItem
                  secondaryAction={
                    <IconButton edge="start" aria-label="delete">
                      <DeleteOutlineOutlinedIcon color="error" onClick={() => handleRemove(index, el.cantidad)} />
                    </IconButton>
                  }
                  sx={SHOPPING_CART_STYLES.product}
                >
                  <ListItemAvatar>
                    <Avatar variant="square" sx={SHOPPING_CART_STYLES.imageContainer}>
                      <img src={el?.Imagen} alt={el?.Nombre} style={SHOPPING_CART_STYLES.imageContainer} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText>
                    <Typography variant="body1" color="primary">
                      {el?.Nombre}
                    </Typography>
                    <Typography variant="subtitle2" color="GrayText">
                      {el?.Marca}
                    </Typography>
                    <Typography variant="subtitle2">Cant: {formatNumber(el?.cantidad)}</Typography>
                    <Typography variant="subtitle2" color="darkorange">
                      {formatNumber(el?.puntos)} pts
                    </Typography>
                  </ListItemText>
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};
