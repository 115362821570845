import { formatDate, formatNumber } from '.';
import {
  IConsolidatedPersonActions,
  IConsolidatedPersonData,
  ITransactionFormatTableRows,
  movimientoDetalle,
  movementType,
} from '../common/types';

export const recentTransactionsFormatTableRows = (
  rows: IConsolidatedPersonData[],
  actions: IConsolidatedPersonActions[]
): ITransactionFormatTableRows[] => {
  return rows?.map(({ idMovimiento, fecha, tipoMovimientoDescripcion, puntos }) => {
    return {
      idMovimiento,
      fecha: formatDate(fecha),
      tipoMovimientoDescripcion,
      puntos: formatNumber(puntos),
      comentario: actions.find((transactions) => transactions.idMovimiento === idMovimiento)?.detalle,
    };
  });
};

export const getMovementTypeDetailHTML = (movementType: movementType) => {
  if (!movementType) return '<p>No se encontraron detalles para este tipo de movimiento</p>';

  let additionalDetails = '';

  if (movementType.descripcion === 'Canje') {
    const productos =
      movementType.movimientoDetalles?.map((detalle: movimientoDetalle) => detalle.idProducto).join(', ') || '-';
    const precios =
      movementType.movimientoDetalles?.map((detalle: movimientoDetalle) => detalle.importe).join(', ') || '-';
    const estadoCanje = movementType.idEstado || '-';

    additionalDetails = `
      <p><strong>Producto/s:</strong> ${productos}</p>
      <p><strong>Precio del producto/s:</strong> ${precios}</p>
      <p><strong>Estado del canje:</strong> ${estadoCanje}</p>
    `;
  } else if (movementType.descripcion === 'Reconocimientos') {
    const quienMeReconocio = movementType.movimientoDetalles?.[0]?.nombrePersonaRegalo || '-';
    const tipoReconocimiento = movementType.movimientoDetalles?.[0]?.comentarioRegalo || '-';

    additionalDetails = `
      <p><strong>Quién me reconoció:</strong> ${quienMeReconocio}</p>
      <p><strong>Tipo de reconocimiento:</strong> ${tipoReconocimiento}</p>
    `;
  } else if (movementType.descripcion === 'Cripto') {
    const moneda = movementType.movimientoDetalles?.[0]?.nombrePersonaRegalo || '-';
    const monto = movementType.movimientoDetalles?.[0]?.importe || '-';

    additionalDetails = `
      <p><strong>Moneda:</strong> ${moneda}</p>
      <p><strong>Monto:</strong> ${monto}</p>
    `;
  }

  return `
    <div>
      <h3>Detalles del Movimiento: ${movementType.descripcion || '-'}</h3>
      <p><strong>ID:</strong> ${movementType.idMovimiento || '-'}</p>
      <p><strong>Comentario:</strong> ${movementType.comentario || '-'}</p>
      <p><strong>Fecha:</strong> ${movementType.fecha || '-'}</p>
      <p><strong>Estado:</strong> ${movementType.idEstado || '-'}</p>
      <p><strong>Puntos:</strong> ${movementType.movimientoDetalles?.[0]?.puntos || '-'}</p>
      ${additionalDetails}
    </div>
  `;
};
