import { useEffect, useState } from 'react';
import { getPointsPerAmountByIdProducto } from '../../services/client';
import { setClosestExchange, validateExchange } from '../../utils';
import { cliente } from '../../common/constants';
import { handleOnInputChangeProps } from '../../common/types';
import { ID_TYPE_OF_TRANSACTION } from '../../common/constants/client';

interface CustomCountProps {
  minPrice: number;
  maxPrice: number;
  deltaIncrementPrice: number;
  idUsuario: number;
  idProducto: number;
}

export const useCustomCount = ({
  maxPrice,
  minPrice,
  deltaIncrementPrice,
  idUsuario,
  idProducto,
}: CustomCountProps) => {
  const [salePrice, setSalePrice] = useState<number>(0);
  const [offerPrice, setOfferPrice] = useState<number>(0);
  const [points, setPoints] = useState<number>(0);
  const [prevPoints, setPrevPoints] = useState<number>(0);
  const [amount, setAmount] = useState<number>(0);
  const [isOffer, setIsOffer] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const exchangeOptions = {
    minPrice,
    maxPrice,
    deltaIncrementPrice,
    value: +amount,
  };

  const isAValidExchange: boolean = validateExchange({ ...exchangeOptions, value: amount });

  const calculatePoints = async (idProducto: number, importe: number): Promise<void> => {
    await getPointsPerAmountByIdProducto({
      idCliente: cliente,
      idUsuario,
      idTipoMovimiento: ID_TYPE_OF_TRANSACTION.redeem,
      importe,
      idProducto,
    })
      .then((puntosData) => {
        setPoints(puntosData?.oferta ? puntosData?.puntosOferta : puntosData?.puntos);
        setOfferPrice(puntosData?.oferta ? puntosData?.puntosOferta : puntosData?.puntos);
        setIsOffer(puntosData?.oferta);
        setPrevPoints(puntosData?.puntos);
        setSalePrice(puntosData?.puntos);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleArrowOnClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    const button: string = e.currentTarget.id;

    const closestExchange: number = setClosestExchange({ ...exchangeOptions, button });
    setAmount(closestExchange);
    calculatePoints(idProducto, closestExchange);
  };

  const getCurrency = (codigoMoneda: string): string => {
    const isInt: boolean = !isNaN(parseInt(codigoMoneda));
    return isInt ? '' : codigoMoneda;
  };

  const handleOnInputChange = ({ maxPrice, minPrice, deltaIncrementPrice, value }: handleOnInputChangeProps): void => {
    validateExchange({
      minPrice,
      maxPrice,
      deltaIncrementPrice,
      value,
    });
    calculatePoints(idProducto, value);
    setAmount(value);
  };

  useEffect(() => {
    setIsError(!isAValidExchange);
  }, [isAValidExchange]);

  return {
    maxPrice,
    points,
    isOffer,
    prevPoints,
    isError,
    amount,
    offerPrice,
    salePrice,
    setAmount,
    setPoints,
    getCurrency,
    handleArrowOnClick,
    calculatePoints,
    handleOnInputChange,
    setIsError,
    setPrevPoints,
  };
};
