import { useContext, useEffect, useMemo, useState } from 'react';
import {
  IConsolidatedPersonActions,
  IFormatTableColumns,
  ISortDirections,
  ITransactionColumns,
  ITransactionExcludeSortingColumns,
  ITransactionFormatTableRows,
  ITransactionsDictionary,
  ITransactionsSort,
  TypeOfMovement,
} from '../../common/types';
import useLanguage from '../../common/hooks/useLanguage';
import { LanguageContext, ThemeContext } from '../../context';
import ES from '../../common/language/transactions/es.json';
import EN from '../../common/language/transactions/en.json';
import { useTransactionsPerson } from '../../common/hooks';
import { CANTIDAD_REGISTROS_POR_PAGINA } from '../../common/constants/constants';
import { formatTableColumns, getMovementTypeDetailHTML, recentTransactionsFormatTableRows } from '../../utils';
import { Chip } from '@mui/material';
import Swal from 'sweetalert2';

export const useMyTransactions = () => {
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [selectedTypeFromInput, setSelectedTypeFromInput] = useState<string>('');
  const [selectedTypeFromTable, setSelectedTypeFromTable] = useState<number>();
  const [typesOfMovements, setTypesOfMovements] = useState<TypeOfMovement[]>([]);
  const [sort, setSort] = useState<ITransactionsSort>({
    column: ITransactionColumns.fecha,
    direction: ISortDirections.desc,
  });
  const { language } = useContext(LanguageContext);
  const { dictionary } = useLanguage({ EN, ES }) as { dictionary: ITransactionsDictionary };
  const { theme } = useContext(ThemeContext);

  const changeSort = (column: string): void => {
    const getColumn = ITransactionColumns[column];
    const getDirection = sort.direction === ISortDirections.asc ? ISortDirections.desc : ISortDirections.asc;

    setSort({
      column: getColumn,
      direction: getDirection,
    });
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number): void => {
    event.preventDefault();
    setPage(value);
  };

  const {
    consolidatedQuery,
    consolidatedSingleMovementType: consolidatedMovementType,
    consolidatedAllMovementType,
    consolidatedModalDetail,
  } = useTransactionsPerson({
    idMovimiento: selectedTypeFromTable,
    idTipoMovimiento: selectedTypeFromInput,
    transactionsPerson: {
      idTipoMovimiento: selectedTypeFromInput,
      idioma: language,
      pagina: page,
      ordenamiento: `${sort.column} ${sort.direction}`,
      cantidadRegistrosPorPagina: CANTIDAD_REGISTROS_POR_PAGINA,
    },
  });

  const myTransactionsColumns: IFormatTableColumns[] = formatTableColumns(dictionary.table.column, [
    ITransactionExcludeSortingColumns.comentario,
  ]);

  const status: IConsolidatedPersonActions[] = useMemo(() => {
    return consolidatedQuery.data?.data.map((el) => {
      const detalle: React.ReactNode = (
        <Chip
          onClick={() => {
            setSelectedTypeFromTable(el.idMovimiento);
          }}
          color="primary"
          label={dictionary.table.actions.detail.title}
        />
      );

      return {
        idMovimiento: el.idMovimiento,
        detalle,
      };
    });
  }, [consolidatedQuery.data?.data, consolidatedMovementType.data, consolidatedModalDetail, dictionary]);

  useEffect(() => {
    if (consolidatedAllMovementType?.data) {
      const uniqueTypes = consolidatedAllMovementType?.data?.map((item) => ({
        id: item.idTipoMovimiento,
        descripcion: item.descripcion,
      }));

      const uniqueTypesSet = Array.from(new Set(uniqueTypes.map((a) => a.id))).map((id) =>
        uniqueTypes.find((a) => a.id === id)
      );

      setTypesOfMovements(uniqueTypesSet);
    }
  }, [consolidatedAllMovementType?.data]);

  useEffect(() => {
    if (consolidatedModalDetail.isSuccess && !consolidatedModalDetail.isLoading) {
      Swal.fire({
        position: window.screen.width > 600 ? 'center' : 'top',
        title: dictionary.table.actions.detail.text,
        text: consolidatedModalDetail.data?.comentario ?? '-',
        html: getMovementTypeDetailHTML(consolidatedModalDetail?.data),
        showCancelButton: true,
        cancelButtonText: dictionary.table.actions.detail.buttons.cancel,
        didClose: () => setSelectedTypeFromTable(null),
        imageHeight: '19rem',
        showConfirmButton: false,
        imageUrl: '',
        allowEscapeKey: true,
        customClass: {
          popup: 'popup',
          title: 'title',
          cancelButton: 'cancelBtn',
        },
      });
    }
  }, [
    consolidatedModalDetail?.isSuccess,
    consolidatedModalDetail?.isLoading,
    consolidatedModalDetail?.data,
    consolidatedQuery?.data,
    selectedTypeFromTable,
    dictionary,
  ]);

  useEffect(() => {
    if (consolidatedQuery.data?.cantidadPaginas) {
      setTotalPages(consolidatedQuery.data.cantidadPaginas);
      return;
    }
  }, [consolidatedQuery.data?.cantidadPaginas, setTotalPages]);

  const myTransactionsRows: ITransactionFormatTableRows[] = recentTransactionsFormatTableRows(
    consolidatedQuery.data?.data.filter(
      (item) => !selectedTypeFromInput || item.idTipoMovimiento === parseInt(selectedTypeFromInput)
    ) ?? [],
    status
  );

  return {
    isLoading: consolidatedQuery.isLoading || consolidatedMovementType.isLoading,
    myTransactionsColumns,
    myTransactionsRows,
    page,
    totalPages,
    search,
    sort,
    language,
    dictionary,
    theme,
    setPage,
    setSelectedType: setSelectedTypeFromInput,
    selectedType: selectedTypeFromInput,
    setSearch,
    setSort,
    changeSort,
    handleChangePage,
    typesOfMovements,
  };
};
