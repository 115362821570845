import React from 'react';
import { ISortDirections } from '.';

export interface ITransactionsPerson {
  idioma: string;
  cantidadRegistros: number;
}

export interface IConsolidatedPerson {
  cantidadTotalRegistros: number;
  cantidadPaginas: number;
  data: IConsolidatedPersonData[];
  cantidadRegistros: number;
  pagina: number;
  idTipoMovimiento?: number | string;
}

export interface ITransactionsSort {
  column: ITransactionColumns;
  direction: ISortDirections;
}

export enum ITransactionColumns {
  idMovimiento = 'idMovimiento',
  fecha = 'fecha',
  idTipoMovimiento = 'idTipoMovimiento',
  puntos = 'puntos',
}

export interface ITransactionFormatTableColumn {
  idMovimiento: string;
  fecha: string;
  tipoMovimientoDescripcion: string;
  puntos: string;
  comentario: string;
}

export interface ITransactionFormatTableRows {
  idMovimiento: number;
  fecha: string;
  tipoMovimientoDescripcion: string;
  puntos: string;
  comentario: React.ReactNode;
}

export enum ITransactionExcludeSortingColumns {
  comentario = 'comentario',
}

export interface ITransactionsDictionary {
  title: string;
  table: Table;
  pagination: Pagination;
  form: Form;
}

interface Form {
  search: Search;
}

interface Search {
  placeHolder: string;
}

interface Pagination {
  first: string;
  last: string;
  next: string;
  previous: string;
}

interface Table {
  column: ITransactionFormatTableColumn;
  row: ITransactionFormatTableRows;
  actions: Actions;
  noResults: string;
  loading: string;
}

interface Actions {
  status: ITransactionsStatus;
  detail: Detail;
}

interface ITransactionsStatus {
  approved: string;
  pending: string;
  delivered: string;
}

export enum ITransactionsStatusValues {
  pending = 'Pendiente',
  inPreparation = 'En preparación',
  delivered = 'Entregado',
}

interface Detail {
  title: string;
  text: string;
  buttons: Buttons;
}

interface Buttons {
  cancel: string;
}

export interface IConsolidatedPersonData {
  idMovimiento: number;
  puntos: number;
  fecha: Date;
  idTipoMovimiento: number;
  tipoMovimientoDescripcion: string;
  idEstado: number | null;
  estado: null | string;
  tieneProductos: boolean;
  idsProductos: number[];
  comentario: null | string;
  idPersona: number;
  productosDescripcion: null | string;
  fechaCorta: string;
  horaCorta: string;
}

export interface IConsolidatedPersonProps {
  idioma: string;
  ordenamiento: string;
  pagina: number;
  cantidadRegistrosPorPagina: number;
  movimientoDescripcion?: string;
  idTipoMovimiento?: number | string;
}

export interface IConsolidatedPersonActions {
  idMovimiento: number;
  detalle: React.ReactNode;
}

export type movimientoDetalle = {
  idProducto: string;
  importe: number;
  nombrePersonaRegalo: string;
  comentarioRegalo: string;
  puntos: number;
};
export type movementType = {
  descripcion?: string;
  movimientoDetalles?: movimientoDetalle[];
  idEstado: number | string;
  idMovimiento: number | string;
  comentario: string;
  fecha: string;
};

export interface Transaction {
  idMovimiento: number;
  id: number;
  idCliente: number;
  fecha: Date;
  idPersona: number;
  idTipoMovimiento: number;
  idMovimientoRelacionado: null;
  idPuntoEntrega: number | null;
  idFormaEntrega: number | null;
  idUsuarioAsignador: number | null;
  comentario: string;
  idRegla: number | null;
  idEstado: number | null;
  idPedido: number | null;
  idClienteNavigation: null;
  idPersonaNavigation: null;
  idTipoMovimientoNavigation: null;
  movimientoDetalles: TransactionDetail[];
  movimientoDomicilioEntregas: unknown[];
  idUsuarioAsignadorNavigation: null;
  idEstadoNavigation: null;
  ctcorders: null;
  tangoOrders: null;
}

interface TransactionDetail {
  idMovimientoDetalle: number;
  id: number;
  idMovimiento: number;
  idProducto: number | null;
  puntos: number;
  importe: number | null;
  idClienteConversion: number | null;
  numeroComprobante: null | string;
  fechaVencimiento: Date | null;
  idEstado: number | null;
  cantidad: number | null;
  esRegalo: boolean | null;
  nombrePersonaRegalo: string | null;
  emailPersonaRegalo: string | null;
  comentarioRegalo: null | string;
  idClienteConversionNavigation: null;
  idProductoNavigation: null;
  idEstadoNavigation: null;
  movimientoPresupuestos: unknown[];
}

export interface PostTransaction {
  idCliente: number;
  fecha: Date | string;
  idPersona: number;
  idTipoMovimiento: number;
  comentario: string;
  idPuntoEntrega?: number;
  idFormaEntrega?: number;
  idUsuarioAsignador?: number;
  idMovimientoRelacionado?: number;
  idRegla?: number;
  idPedido?: number;
  idEstado?: number;
  id?: number;
  movimientoDetalles: TransactionDetailProps[];
}

interface TransactionDetailProps {
  puntos?: number; //a___
  importe?: number;
  idClienteConversion?: number;
  idProducto?: number;
  idEstado?: number;
  cantidad?: number;
}

export interface NewTransactionResponse {
  idMovimiento: number;
  id: number;
  idCliente: number;
  fecha: Date;
  idPersona: number;
  idTipoMovimiento: number;
  idMovimientoRelacionado: null;
  idPuntoEntrega: number | null;
  idFormaEntrega: null;
  idUsuarioAsignador: null;
  comentario: string;
  idRegla: null;
  idEstado: null;
  idPedido: null;
  idClienteNavigation: IDClienteNavigation;
  idPersonaNavigation: null;
  idTipoMovimientoNavigation: ClienteConversionIDTipoMovimientoNavigation;
  movimientoDetalles: TransactionDetail[];
  movimientoDomicilioEntregas: unknown[];
  idUsuarioAsignadorNavigation: null;
  idEstadoNavigation: null;
  ctcorders: null;
  tangoOrders: null;
}

interface BudgetTransaction {
  idMovimientoPresupuesto: number;
  id: number;
  idCliente: number;
  idMovimientoDetalle: number;
  puntos: number;
  idTipoMovimiento: number;
  idUsuario: number;
  idSupervisor: null;
  fechaAlta: Date;
  comentarios: string;
  idTipoMovimientoNavigation: BudgetTransactionIDTipoMovimientoNavigation;
  idMovimientoDetalleNavigation?: TransactionDetail;
  idClienteNavigation?: IDClienteNavigation;
}

interface ClienteConversionIDTipoMovimientoNavigation {
  idTipoMovimiento: number;
  id: number;
  descripcion: string;
  accionSobreStock: string;
  validaPresupuesto: boolean;
  clienteConversions: Ion[];
  movimientos: unknown[];
}

interface Ion {
  idClienteConversion: number;
  id: number;
  idCliente: number;
  importe: number;
  puntos: number;
  fechaVigencia: Date;
  idTipoMovimiento: number;
  diasVencimiento: number | null;
  cotizacion: number;
  spread: number;
  idNivelUsuario: number;
  idCategoriaUsuario: number;
  idTipoMovimientoNavigation?: ClienteConversionIDTipoMovimientoNavigation | null;
  movimientoDetalles: TransactionDetail[];
  idCategoriaUsuarioNavigation: null;
  idNivelUsuarioNavigation: null;
  idClienteNavigation?: IDClienteNavigation;
}

interface IDClienteNavigation {
  idCliente: number;
  id: number;
  razonSocial: string;
  cuit: string;
  email: string;
  domicilio: string;
  localidad: string;
  provincia: string;
  pais: string;
  cp: string;
  sfi: number;
  fechaAlta: Date;
  clienteDvh: string;
  logo: string;
  idFormaEnvioEmail: number;
  dominio: null;
  validaEnvioMailPorDominio: boolean;
  url: string;
  idProvincia: string;
  idLocalidad: string;
  idPais: string;
  idMoneda: string;
  idTipoGestionPresupuesto: number;
  asignaNivelUsuarioDinamico: boolean;
  cantidadReintentosBloqueoUsuario: number;
  emailNotificacionCliente: string;
  emailNotificacionAdmin: string;
  idModoReconocimiento: number;
  umbralPuntosPresupuesto: number;
  imagenBase64: string;
  minutosExpiracionSession: number;
  idModoReconocimientoNavigation: null;
  usuarioClientes: UsuarioCliente[];
  clienteConversion: Ion[];
  clienteProductos: unknown[];
  movimientos: unknown[];
  tipoProductos: unknown[];
  categoriaUsuarios: unknown[];
  nivelUsuarios: unknown[];
  clienteReglas: unknown[];
  tipoReconocimientos: unknown[];
  reconocimientos: unknown[];
  sectores: unknown[];
  videos: unknown[];
  clienteLiriumParametros: unknown[];
  colorEstilos: unknown[];
  clienteAccesos: unknown[];
  clienteCatalogoPais: unknown[];
  pedidos: unknown[];
  idMonedaNavigation: null;
  movimientoPresupuestos: BudgetTransaction[];
  clienteSpreads: unknown[];
  idTipoGestionPresupuestoNavigation: null;
}

interface BudgetTransactionIDTipoMovimientoNavigation {
  idTipoMovimiento: number;
  id: number;
  descripcion: string;
  accion: string;
  esIngreso: boolean;
  movimientoPresupuestos: unknown[];
}

interface UsuarioCliente {
  idUsuarioCliente: number;
  id: number;
  idUsuario: number;
  idCliente: number;
  idCategoriaUsuario: number;
  idNivelUsuario: number;
  fechaIngresoEmpresa: null;
  idUsuarioNavigation: null;
  idCategoriaUsuarioNavigation: null;
  idNiverlUsuarioNavigation: null;
}
