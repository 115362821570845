import { API_TRANSACTIONS_URL } from '../../common/constants';
import { IConsolidatedPerson, IConsolidatedPersonProps } from '../../common/types';
import { fetchContent, setQueryParams } from '../../utils';
import { handleServiceError } from '../GiftCardService';

export const getConsolidatedPerson = async (props: IConsolidatedPersonProps): Promise<IConsolidatedPerson> => {
  try {
    const URL: string = setQueryParams(API_TRANSACTIONS_URL.consolidatedPerson, props);

    const response: IConsolidatedPerson = await fetchContent(URL, {
      method: 'GET',
    });
    return response;
  } catch (error: any) {
    console.error('[getConsolidatedPerson]: ' + error);
    throw handleServiceError(error);
  }
};

export const getMovementTypes = async (idTipoMovimiento: number) => {
  try {
    const response = await fetchContent(
      API_TRANSACTIONS_URL.consolidatedMovementTypes.replace('{idTipomovimiento}', String(idTipoMovimiento)),
      { method: 'GET' }
    );
    return response;
  } catch (error: any) {
    console.error('[getMovementTypes]:' + error);
    throw handleServiceError(error);
  }
};

export const getAllMovementType = async (idTipoMovimiento: number) => {
  try {
    const response = await fetchContent(
      API_TRANSACTIONS_URL.consolidateAllMovementTypes.replace('{idTipomovimiento}', String(idTipoMovimiento)),
      { method: 'GET' }
    );
    return response;
  } catch (error: any) {
    console.error('[getAllMovementType]:' + error);
    throw handleServiceError(error);
  }
};

export const getMovementTypeModalDetail = async (idMovimiento: number) => {
  try {
    const url = API_TRANSACTIONS_URL.consolidateMovementModalDetail.replace('{idMovimiento}', String(idMovimiento));
    const response = await fetchContent(url, { method: 'GET' });
    return response;
  } catch (error: any) {
    console.error('[getMovementTypeModalDetail]:' + error);
    throw handleServiceError(error);
  }
};
