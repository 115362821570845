import { CSSProperties } from 'styled-components';

export const SHOPPING_CART_STYLES = {
  cartIcon: {
    fontSize: '20px',
    display: 'flex',
    padding: '1px',
  } as CSSProperties,
  drawer: {
    zIndex: 9999,
  } as CSSProperties,
  dropDownContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    paddingTop: 4.5,
    position: 'relative',
  } as CSSProperties,
  product: {
    gap: '1rem',
  } as CSSProperties,
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    px: '20px',
  } as CSSProperties,
  titleContainer: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  } as CSSProperties,
  imageContainer: {
    width: '80px',
    height: '80px',
  } as CSSProperties,
  cartIconContainerHover: () =>
    ({
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'rgb(0, 71, 89)',
        borderRadius: '5px',
        transform: 'scale(1.2)',
      },
    } as CSSProperties),
  isScrollBarVisible: (cart: number) =>
    ({
      height: cart > 4 ? '100%' : 'auto',
    } as CSSProperties),
};
