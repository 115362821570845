import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AppContext } from '../../../context';
import { IConsolidatedPersonProps, ITransactionsPerson } from '../../types';
import {
  getMovementTypeModalDetail,
  getConsolidatedPerson,
  getConsolidatedPersonHome,
  getAllMovementType,
  getMovementTypes,
} from '../../../services/transactions';

interface ITransactionsPersonProps {
  transactionsPersonHome?: ITransactionsPerson;
  transactionsPerson?: IConsolidatedPersonProps;
  idTipoMovimiento?: string | number;
  idMovimiento?: string | number;
}

export const useTransactionsPerson = ({
  transactionsPersonHome,
  transactionsPerson,
  idTipoMovimiento,
  idMovimiento,
}: ITransactionsPersonProps) => {
  const appContext = useContext(AppContext);
  const idUsuario: number = appContext?.user?.idUsuario;

  const consolidatedHomeQuery = useQuery({
    queryKey: ['transactions-person-consolidated-home', { idUsuario, idioma: transactionsPersonHome?.idioma }],
    queryFn: async () => {
      const res = await getConsolidatedPersonHome({
        idioma: transactionsPersonHome?.idioma,
        cantidadRegistros: transactionsPersonHome?.cantidadRegistros,
      });
      return res;
    },
    enabled: Boolean(idUsuario && transactionsPersonHome),
  });

  const consolidatedQuery = useQuery({
    queryKey: [
      'transactions-person-consolidated',
      {
        idUsuario,
        idTipoMovimiento,
        idioma: transactionsPerson?.idioma,
        pagina: transactionsPerson?.pagina,
        ordenamiento: transactionsPerson?.ordenamiento,
        movimientoDescripcion: transactionsPerson?.movimientoDescripcion,
      },
    ],
    queryFn: async () => {
      const data = await getConsolidatedPerson({
        idioma: transactionsPerson?.idioma,
        ordenamiento: transactionsPerson?.ordenamiento,
        pagina: transactionsPerson?.pagina,
        cantidadRegistrosPorPagina: transactionsPerson?.cantidadRegistrosPorPagina,
        // idTipoMovimiento: idTipoMovimiento,
        ...(transactionsPerson?.movimientoDescripcion && {
          movimientoDescripcion: transactionsPerson.movimientoDescripcion,
        }),
      });
      return data;
    },
    enabled: Boolean(idUsuario && transactionsPerson),
  });

  const consolidatedSingleMovementType = useQuery({
    queryKey: ['transaction-movent-type', idTipoMovimiento],
    queryFn: async () => {
      const res = await getMovementTypes(idTipoMovimiento as number);
      return res;
    },

    enabled: !!idTipoMovimiento,
  });

  const consolidatedAllMovementType = useQuery({
    queryKey: ['transaction-movent-all-type', idTipoMovimiento],
    queryFn: async () => {
      const res = await getAllMovementType(idTipoMovimiento as number);
      return res;
    },
    enabled: !!idTipoMovimiento,
  });

  const consolidatedModalDetail = useQuery({
    queryKey: ['transaction-modal-detail', idMovimiento],
    queryFn: async () => {
      console.log({ idMovimiento });
      const res = await getMovementTypeModalDetail(idMovimiento as number);
      return res;
    },
    enabled: Number.isInteger(idMovimiento),
  });

  return {
    consolidatedHomeQuery,
    consolidatedQuery,
    consolidatedSingleMovementType,
    consolidatedAllMovementType,
    consolidatedModalDetail,
  };
};
