import { useState, useEffect, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { ThemeConfig, ThemeContext } from "./context/ThemeContext";
import { Loading } from "./components";
import GlobalStyles from "./globals";
import { getTheme } from "./utils/functions";
import Layout from "./components/Layout/Layout";
import Login from "./pages/Login/Login";
import RecuperarForm from "./pages/Login/components/RecuperarForm/RecuperarForm";
import LoginForm from "./pages/Login/components/LoginForm/LoginForm";
import { AppContext } from "./context/AppContext";
import { cliente } from "./common/constants/constants";
import "./App.css";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import { FooterInterface } from "./common/interfaces/FooterInterface";
import { GetClienteDatosFooter } from "./services/FooterService";
import { getPermisosUsuario } from "./services/UsuarioService";
import {
  LOGGED_ROUTER_DOM_ROUTES,
  ROUTER_DOM_ROUTES,
} from "./common/constants/rutas";
import { modalError } from "./utils/validaciones";
import CambioPassword from "./pages/Login/components/RecuperarForm/CambioPassword";
import PrivateRoute from "./components/PrivateRoute";
import { Home } from "./pages/Home";

interface RootObject {
  idColorEstilo: number;
  idCliente: number;
  orden: number;
  control: string;
  color: string;
  idClienteNavigation?: any;
}

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [colors, setColors] = useState<RootObject[] | null>(null);
  const [footerInfo, setFooterInfo] = useState<FooterInterface | null>(null);
  const themeContext = useContext(ThemeContext);

  const appContext = useContext(AppContext);

  useEffect(() => {
    if (isLoading) {
      getTheme(cliente)
        .then((data) => {
          themeContext?.setTheme(data);
          setColors(data);
          setIsLoading(false);
        })
        .catch(() => console.error("Error al traer los estilos"))
        .finally(() => setIsLoading(false));
    }
    GetClienteDatosFooter(cliente)
      .then((data) => setFooterInfo(data))
      .catch(() => console.error("error"));
  }, [isLoading, themeContext]);

  useEffect(() => {
    const loadData = () => {
      if (
        sessionStorage.getItem("token") &&
        sessionStorage.getItem("user") !== null
      ) {
        appContext?.setToken(sessionStorage.getItem("token"));
        appContext?.setUser(
          JSON.parse(sessionStorage.getItem("user") || "null")
        );
      }
    };
    loadData();
    if (appContext?.user) {
      getPermisosUsuario(appContext.user.idUsuario)
        .then((data) => appContext.guardarPermisos(data))
        .catch(() => modalError("No se pudo traer los permisos"));
    }
    // eslint-disable-next-line
  }, [appContext?.user?.idUsuario]);

  return (
    <>
      <GlobalStyles
        bgHeader={themeContext?.theme ? themeContext?.theme[4]?.color : null}
        bgFooter={themeContext?.theme ? themeContext?.theme[5]?.color : null}
        colorTitle={themeContext?.theme ? themeContext?.theme[0]?.color : null}
        colorDefault={
          themeContext?.theme ? themeContext?.theme[1]?.color : null
        }
        bgLowerBar={themeContext?.theme ? themeContext?.theme[8]?.color : null}
        bgMainButton={
          themeContext?.theme ? themeContext?.theme[2]?.color : null
        }
        colorFooter={themeContext?.theme ? themeContext?.theme[7]?.color : null}
        footerLane={themeContext?.theme ? themeContext?.theme[6]?.color : null}
      />
      <ThemeConfig colors={colors}>
        <Routes>
          <Route path="/" element={<Layout footerInfo={footerInfo} />}>
            <Route index element={<Home />} />
            <Route element={<PrivateRoute />}>
              <Route index element={isLoading ? <Loading /> : <Home />} />
              {/*  rutas LOGGEADO  */}
              {appContext?.user &&
                LOGGED_ROUTER_DOM_ROUTES.filter((el) =>
                  appContext?.permisos?.find(
                    (permiso) => permiso.codigo === el.codigo
                  )
                ).map((el) => (
                  <Route
                    key={crypto.randomUUID()}
                    path={el.ruta}
                    element={<el.componente />}
                  />
                ))}
              <Route path="*" element={<ErrorPage />} />
            </Route>
            {ROUTER_DOM_ROUTES.map((el) => (
              <Route
                key={crypto.randomUUID()}
                path={el.ruta}
                element={<el.componente />}
              />
            ))}
          </Route>
          {/* paginas LOGIN */}
          <Route
            path="/login"
            element={
              isLoading ? (
                <Loading />
              ) : (
                <Login
                  theme={colors}
                  logoProp={{
                    logoUrl: footerInfo?.logoUrl || "",
                    tipoImagenLogo: footerInfo?.tipoImagenLogo || "",
                    logo: footerInfo?.logo || "",
                  }}
                />
              )
            }
          >
            <Route index element={<LoginForm />} />
            <Route path="/login/recuperar" element={<RecuperarForm />} />

            <Route
              path="cambio-password"
              element={
                <CambioPassword
                  theme={colors}
                  logoProp={{
                    logoUrl: footerInfo?.logoUrl || "",
                    tipoImagenLogo: footerInfo?.tipoImagenLogo || "",
                    logo: footerInfo?.logo || "",
                  }}
                />
              }
            />
          </Route>
          <Route
            path="/web/PrimerAcceso/index"
            element={
              <CambioPassword
                theme={colors}
                logoProp={{
                  logoUrl: footerInfo?.logoUrl || "",
                  tipoImagenLogo: footerInfo?.tipoImagenLogo || "",
                  logo: footerInfo?.logo || "",
                }}
              />
            }
          />
        </Routes>
      </ThemeConfig>
    </>
  );
}

export default App;
